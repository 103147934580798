var admin_email = "oanapopescu93@gmail.com"
var word_bank = {
    salon: "Salón",
    about: "Acerca de",
    questions: "Preguntas",
    terms_cond: "Términos y condiciones",
    policy_privacy: "Política de privacidad",
    contact: "Contacto",
    career: "Carrera",
    info: "Info",
    back: "Volver",
    bet_type: "Tipo de apuesta",
    and: "y",
    or: "o",
    i_agree_to: "Estoy de acuerdo con",

    // games
    games: "Juegos",
    roulette: "Roulette",
    blackjack: "Blackjack",
    slots: "Slots",
    craps: "Craps",
    race: "Carrera",
    keno: "Keno",
    poker: "Poker",
    baccarat: "Baccarat",
    european: "Europeo",
    american: "Americano",
    reel_3: "3 Rodillos",
    reel_5: "5 Rodillos",
    poker_texas_holdem: "Texas hold'em",
    poker_5_card_draw: "5 Card Draw",
    responsible_gamble_request: "¡Por favor, juega con responsabilidad!",

    success: "Éxito",
    error: "Error",
    all_rights_reserved: "Todos los derechos reservados",
    
    cookies_modal_title: "Notificación de cookies",
    cookies_modal_text: "Con el fin de ofrecerte la información más relevante y un rendimiento óptimo del sistema, utilizamos cookies que recopilan información de tu actividad de juego.",

    sign_in: "Iniciar sesión",
    sign_up: "Registrarse",
    sign_up_info: "Por favor, introduce un correo electrónico válido para ser verificado por correo electrónico",
    signin_error: "El usuario no existe",
    signup_error: "Ha habido un error al registrarte. Por favor, envía este error a " + admin_email + " o inténtalo de nuevo más tarde.",
    signup_error_email_verification: "Your account has not been verified. Check your email.",
    email_in_use: "Este correo electrónico ya ha sido utilizado",
    user: "Usuario",
    password: "Contraseña",
    remember_me: "Recuérdame",
    sign_in_email_empty: "El correo electrónico está vacío",
    sign_in_user_empty: "El usuario está vacío",
    sign_in_pass_empty: "La contraseña está vacía",
    sign_in_email_error: "El correo electrónico es incorrecto",
    sign_in_pass_error: "La contraseña es incorrecta",
    signin_forgot_password: "Olvidé mi contraseña",
    sign_problem: "¿Problemas para iniciar sesión o registrarte?",
    signProblem_message: "Si tienes problemas para iniciar sesión o registrarte, contáctanos utilizando el correo electrónico que proporcionaste durante el registro para que podamos investigar el problema y ayudarte lo antes posible.",
    subtitle: "Atrévete a atrapar al conejo",
    country: "País",
    city: "Ciudad",
    your_streak: "Cuántos días seguidos has jugado",
    streak_prize_text: "Si juegas 10 días seguidos, obtendrás un premio",

    exit_salon: "Salir del salón",
    salon_subtitle: "Bienvenido al salón",
    exit_game: "Salir del juego",

    donation: "Donaciones",
    crypto: "Cripto",
    paypal: "Paypal",
    bank: "Banco",
    crypto_donation_title: "Donar a través de Criptomonedas",
    crypto_donation_text: "Por favor, envíe su donación en criptomonedas a las siguientes direcciones. Una vez enviada la donación, puede tomar algún tiempo para confirmarse en la red de blockchain.",
    paypal_donation_title: "Donar a través de PayPal",
    paypal_donation_text: "Por favor, utilice los siguientes enlaces para hacer una donación a través de PayPal. Una vez completada la donación, puede tomar algún tiempo para procesarse.",
    bank_donation_title: "Donar a través de Transferencia Bancaria",
    bank_donation_text: "Por favor, envíe su donación a la siguiente cuenta bancaria. Una vez transferida la donación, puede tomar algún tiempo para reflejarse en la cuenta.",
    donation_footer_text: "¡Gracias por su generosidad!",
    donation_ukraine: "Donación para Ucrania",
    donate_with_nowpayments: "Donar con NOWPayments",

    all: "Todos",
    other: "Otros",
    title: "Título",
    location: "Ubicación",
    requirements: "Requisitos",
    responsabilities: "Responsabilidades",
    nice_to_have: "Nice to have",
    marketing: "Marketing", 

    under_construction: "En construcción",

    no_data: "Sin información",
    no_bets: "No has realizado ninguna apuesta",
    no_user: "No se encontró ningún usuario",
    no_money: "No tienes suficientes zanahorias. Puedes comprarlas en el mercado.",
    no_payment_methods: "Aún no hay métodos de pago",
    no_cart: "El carrito está vacío",
    no_history: "Sin historial",
    no_jobs: "Todavía no hay trabajos disponibles. Pero puedes enviarnos tu CV.",
    no_participation_today: "Sin participación hoy",
    no_order: "Sin pedidos",
    no_selections: "Selecciona al menos un punto de Keno para iniciar el juego",
    no_match: "Sin coincidencia",
    keno_too_many_selected: "Demasiados puntos seleccionados",

    isMinor_title: "Verificación de edad",
    isMinor_text: "¿Tienes más de 18 años?",
    yes: "Sí",
    no: "No",
    isMinor_sign: "No puedes ingresar porque eres menor de edad.",

    forgot_password_title: "¿Olvidaste tu contraseña?",
    forgot_password_text: "Por favor, ingresa la dirección de correo electrónico utilizada para registrarte. Te enviaremos tu nueva contraseña a esa dirección.",
    send: "Enviar",
    email_send: "El correo electrónico ha sido enviado.",
    email_no_send: "No se ha enviado el correo electrónico.",
    subject: "Asunto",
    message: "Mensaje",
    sending: "Enviando...",

    incorrect_email: "El correo electrónico es incorrecto",
    empty_input_subject: "El campo de asunto está vacío",
    empty_input_about: "El campo acerca de está vacío",
    empty_input_message: "El campo de mensaje está vacío",
    empty_input_agree: "No has aceptado los Términos y condiciones y la Política de privacidad",
    account_issues: "Problemas con la cuenta", 
    payments_and_withdrawals: "Pagos y retiros", 
    game_issues: "Problemas del juego", 
    hiring: "Contratación", 

    market: "Mercado",
    settings: "Configuración",
    how_to_play: "Cómo jugar",
    logout: "Cerrar sesión",
    joined_the_chat: "se unió al chat",
    left_the_chat: "salió del chat",
    buy: "Comprar",
    play: "Jugar",
    play_whack_a_rabbit: "¡Juega a Whack a Rabbit para conseguir zanahorias gratis!",

    language: "Idioma",
    date_calendar: "Fecha",
    currency: "Moneda",
    theme: "Tema",
    red: "Rojo",
    orange: "Naranja",
    yellow: "Amarillo",
    green: "Verde",
    blue: "Azul",
    brown: "Marrón",
    purple: "Púrpura",
    black: "Negro",
    grey: "Gris",
    white: "Blanco",
    forest_green: "Verde Bosque",
    saphire_purple: "Púrpura Zafiro",
    radium_black: "Negro Radio",
    copper_night: "Noche de Cobre",

    dashboard: "Dashboard",
    choose_profile_pic: "Elegir foto de perfil",
    animal: "Animal",
    carrots: "Zanahorias",
    change_username: "Cambiar nombre de usuario",
    change_password: "Cambiar contraseña",
    buy_carrots: "Comprar zanahorias",    
    choose: "Elegir",
    change: "Cambiar",
    profile_grey: "Para tener esta imagen, necesitas tener más de 1000 zanahorias",
    empty_input_change_username: "El nombre de usuario no puede estar vacío",
    password_rule01: "Al menos una letra mayúscula",
    password_rule02: "Al menos una letra minúscula",
    password_rule03: "Al menos un dígito",
    password_rule04: "Al menos un carácter especial",
    password_rule05: "Mínimo ocho caracteres de longitud",

    //cart
    value: "Valor",
    price: "Precio",
    qty: "Cantidad",
    total_price: "Precio total",
    cart: "Carrito",
    cart_empty: "El carrito está vacío",
    order_summary: "Resumen del pedido",
    subtotal: "Subtotal",
    shipping: "Envío",
    promo_code: "Código de descuento",
    promo_discount: "Descuento",
    total: "Total",
    checkout: "Pagar",
    apply_coupon: "Aplicar cupón",
    remove_all: "Eliminar todo",
    continue_shopping: "Continuar comprando",
    coupon_not_valid: "Tu cupón no es válido",

    //checkout"
    customer_info: "Información del cliente",
    name: "Nombre",
    email: "Email",
    phone: "Teléfono",
    payment_info: "Información de pago",
    cash_on_delivery: "Pago contra entrega",
    pay_card: "Tarjeta de crédito o débito",
    pay_paypal: "Paypal",
    pay_crypto: "Criptomoneda",
    pay_google: "Google Pay",
    card_number: "Número de tarjeta",
    month: "Mes",
    year: "Año",
    cvv: "CVV",
    bitcoin_wallet: "Dirección de Bitcoin",
    error_charge: "Lo sentimos, pero no podemos cobrarte debido a un error por nuestra parte. Repararemos este error lo antes posible.",
    amount_too_low: "El monto es demasiado bajo.",
    createSource_error: "Error al adjuntar la tarjeta al cliente.",
    addNewCard_error: "Error al crear un token de tarjeta.",
    submit: "Enviar",
    pay: "Pago",
    min_amount: "Monto mínimo",
    fiat_equivalent: "Equivalente en moneda fiduciaria",
    your_amount_in_fiat_equivalent: "Su cantidad en equivalente fiat",
    amount_too_small_transaction: "El monto es demasiado pequeño para realizar una transacción",
    payment_details: "Detalles de pago",
    payment_methode: "Método de pago",
    january: "Enero",
    february: "Febrero",
    march: "Marzo",
    april: "Abril",
    may: "Mayo",
    june: "Junio",
    july: "Julio",
    august: "Agosto",
    september: "Septiembre",
    october: "Octubre",
    november: "Noviembre",
    december: "Diciembre",
    fill_field: "Por favor complete este campo",
    fill_field_email: "Please fill email",
    fill_field_phone: "Please fill phone number",
    fill_field_user: "Please fill username",
    fill_field_pass: "Please fill password",
    fill_field_cv: "Please fill CV.pdf",
    fill_field_checkboxOne: "Please agree to the Terms and Conditions and Privacy Policy to complete your signup",
    validate_message_name: "Esto no es un nombre",
    validate_message_email: "Esto no es un correo electrónico válido",
    validate_message_phone: "Esto no es un número de teléfono válido",
    validate_message_country: "Esto no es un país válido",
    validate_message_city: "Esto no es una ciudad válida",
    validate_message_cardNumber: "Esto no es un número de tarjeta válido",
    validate_message_month: "Esto no es un mes válido",
    validate_message_year: "Esto no es un año válido",
    validate_message_cvv: "Esto no es un CVV válido",
    validate_message_bitcoinAddress: "Esto no es una dirección de Bitcoin válida",
    validate_message_iban: "Esto no es un IBAN",
    continue: "Continuar",
    save: "Guardar",
    payment_success: "Pago exitoso",
    payment_success_text: "Ha completado su pago",
    payment_cancel: "Pago cancelado",
    payment_cancel_text: "Ha cancelado su pago",
    order_info: "Información del pedido",
    payment_id: "ID de pago",
    date: "Fecha",
    description: "Descripción",
    customer_id: "ID del cliente",
    items: "Artículos",
    amount: "Monto",
    requires_payment_method: "Método de pago requerido",
    requires_confirmation: "Requiere confirmación",
    requires_action: "Requiere acción",
    processing: "Procesando",
    requires_capture: "Requiere captura",
    canceled: "Cancelado",
    succeeded: "Exitoso",
    createNewCustomer_error: "No se pudo crear el cliente. Por favor, inténtelo de nuevo.",
    createPaymentMethod_error: "No se pudo crear el método de pago. Por favor, verifique los datos de su tarjeta.",
    attachPaymentMethod_error: "No se pudo adjuntar el método de pago al cliente.",
    paymentIntent_error: "No se pudo procesar el pago. Por favor, inténtelo de nuevo más tarde.",
    
    // order
    order: "Pedido",
    orders: "Pedidos",
    order_message: "¡Tu pedido se realizó correctamente!",
    order_details: "Detalles del pedido",
    customer: "Cliente",
    payment_method: "Método de pago",
    status: "Estado",
    status_fullfilled: "Completado",
    status_unfullfilled: "No completado",
    order_link: "Enlace al recibo",
    order_description: "Descripción del pedido",
    order_date: "Fecha del pedido",
    postal_code: "Código postal",
    id: "ID",
    method: "Método",

    // withdraw
    withdrawal: "Retiro",
    withdraw: "Retirar",
    withdrawals: "Retiros",
    no_withdrawal: "Sin retiros",
    not_enough_money_withdrawal: "No tienes suficiente dinero para hacer un retiro.",
    withdraw_instructions: "Para solicitar un retiro, complete el formulario a continuación. Asegúrese de que todos los campos obligatorios estén completados correctamente. Seleccione el monto y la moneda deseada, y proporcione su nombre completo, número de teléfono, dirección de correo electrónico y IBAN para la transacción. Después de enviar la solicitud, recibirá un correo electrónico de confirmación y nuestro equipo procesará su solicitud. Los tiempos de procesamiento pueden variar según el método de retiro y los requisitos de verificación. Si tiene alguna pregunta o problema, comuníquese con soporte.",
    withdraw_success: "Retiro exitoso",
    withdraw_failed: "Retiro fallido",
    instructions: "Instrucciones",
    min_amount_withdraw: "Cantidad mínima de zanahorias para realizar un retiro",
    convert_carrots_rate: "Tasa de conversión de zanahorias",

    // blackjack
    start: "Comenzar",
    hit: "Pedir carta",
    stand: "Plantarse",
    double_down: "Doblar apuesta",
    surrender: "Rendirse",
    not_current_player: "No es tu turno",
    hit_explanation: "Tome otra carta para aumentar su mano.",
    stand_explanation: "Mantenga su mano actual y termine su turno.",
    double_down_explanation: "Doble su apuesta, reciba una carta más.",
    surrender_explanation: "Renuncie a su apuesta para terminar su mano.",
    table_1: "Mesa 01", 
    table_2: "Mesa 02", 
    table_3: "Mesa 03", 
    table_4: "Mesa 04", 
    table_5: "Mesa 05", 
    table_6: "Mesa 06", 
    table_7: "Mesa 07", 
    table_8: "Mesa 08", 
    reset: "Restablecer",

    // race
    breed: "Raza",
    delay: "Retraso",
    health: "Salud",
    bet: "Apuesta",
    place: "Posición",
    place_01: "1er lugar",
    place_02: "2º lugar",
    place_03: "3er lugar",

    // Keno
    keno_instructions: "Elige hasta 10 números en Keno o selecciona 'Selección rápida' (elegirá aleatoriamente hasta 10 números en Keno para ti)",
    price_per_game: "Precio por juego",
    no_of_games: "Número de juegos",
    quick_pick: "Selección rápida",
    keno_prizes: "Premios de Keno",
    keno_numbers_played: "Cantidad de números jugados",
    keno_numbers_matched: "Cantidad de números acertados",
    keno_win: "Ganancia",
    your_numbers: "Tus números",
    lucky_numbers: "Números de la suerte",

    // slots
    slots_prizes: "Premios de tragamonedas",

    // poker
    total_pot: "Bote total",
    check: "Pasar",
    check_explanation: "No realizar apuesta, quedarse en la ronda.",
    fold: "Retirarse",
    fold_explanation: "Abandonar tu mano ahora.",
    call: "Igualar",
    call_explanation: "Igualar la apuesta más alta actual.",
    raise: "Subir",
    raise_explanation: "Aumenta la apuesta más alta actual, obligando a otros a igualar o retirarse.",
    showdown: "Showdown",
    showdown_explanation: "Mostrar las manos para determinar al ganador.",
    draw: "Cambiar",
    draw_explanation: "Cambiar cartas por nuevas.",
    cancel: "Cancelar",
    cancel_explanation: "No deseas cambiar cartas.",
    bet_explanation: "Realizar una apuesta inicial.",
    invalid_raise: "Subida inválida",
    not_enough_money: "No hay suficiente dinero",
    not_enough_money_call: "Dinero insuficiente para igualar la apuesta más alta actual",
    small_blind: "Ciega pequeña",

    // baccarat
    player: "Jugador",
    banker: "Banquero",
    tie: "Empate",

    // craps
    dice: "Dado",
    dices: "Dados",
    sum: "Suma",
    point: "Punto",

    // results
    results: "Resultados",
    result: "Resultado",
    game: "Juego",
    game_type: "Tipo de juego",
    you_win: "Ganaste",
    you_lose: "Perdiste",

    welcome: "Bienvenido",
    welcome_gift: "Regalo de bienvenida",
    welcome_text: "100 zanahorias gratis!",
    prize: "Premio",
    prizes: "Premios",
    our_casino: "nuestro casino",
    
    //401
    page_not_found_text: "Lo siento, la página que busca no existe. Es posible que se haya movido o eliminado, o que haya ingresado incorrectamente la URL.",

    // ChatBot
    chatbot: "ChatBot",
    anonymous: "Anónimo",
    greetings01: "Hola",
    greetings02: "Estoy aquí para responder todas tus preguntas sobre BunnyBet.",
    error_chatbot: "No entiendo tu pregunta.",

    // Email verification
    email_verification: "Verificación de correo electrónico",
    verifying: "Verificando",
    no_token: "Sin token",
    error_during_verification: "Error durante la verificación",
    invalid_expired_token: "Token inválido o expirado",
    email_verify_success: "Correo electrónico verificado con éxito",
    email_already_verified: "El correo electrónico ya ha sido verificado",
    email_send_validation_title: "Verifica tu dirección de correo electrónico",
    email_send_validation_text: "Hemos enviado un correo electrónico de verificación a tu bandeja de entrada. Por favor, revisa tu correo y haz clic en el enlace de verificación para activar tu cuenta. Si no ves el correo, revisa tu carpeta de spam o correo no deseado. ¡Gracias!",
    email_send_validation_button: "Reenviar correo de verificación",
    token_is_not_verified: "Este usuario aún no ha sido verificado. Debe reenviar el correo electrónico de verificación. Después de eso, por favor revise su correo electrónico y haga clic en el enlace de verificación para activar su cuenta. Si no ve el correo electrónico, revise su carpeta de spam o correo no deseado. ¡Gracias!",

    // Apply Job
    apply: "Postúlate al trabajo",

    // Newsletter
    newsletter: "Newsletter",
    company: "Compañía",
    resources: "Recursos",
    links: "Enlaces",
    subscribed: "Suscrito",
    unsubscribed: "Dado de baja",
    already_subscribed: "Ya suscrito",

    // Gambling warning
    gambling_warning_signs: "Señales de advertencia de problemas con el juego",
    warning_gambling_01: "Pedir dinero prestado, vender todo o incluso robar para conseguir dinero para apostar.",
    warning_gambling_02: "Mentir sobre cuánto tiempo o dinero se gasta en el juego.",
    warning_gambling_03: "Ocultar el tiempo dedicado al juego o esconder facturas y deudas impagas.",
    warning_gambling_04: "Pasar mucho tiempo apostando, pensando en apostar o planeando hacerlo.",
    warning_gambling_05: "Sentirse inquieto o irritable cuando no se está apostando.",
    warning_gambling_06: "Presumir de las ganancias, exagerar los éxitos y/o minimizar las pérdidas.",  
    are_you_sure: "¿Estás seguro?",
    are_you_sure_bets: "¿Estás seguro de que quieres apostar esta cantidad?",  
}

export const wordsEs = (info)=>{
    return word_bank[info]
}